import React, { useState, useEffect, useRef } from "react";
import MasterNounster from "./MasterNounster";
import ControlPanel from "../panels/controlPanel";
import ExportPanel from '../panels/exportPanel';
import TraitDisplay from '../panels/traitDisplay';
import palettes from '../Nounster/palettesList.json';
import { useMediaQuery } from 'react-responsive';
import { handleExportTexture } from '../utilities/ExportUtils/UVExportUtils';

const AppHero = () => {
  const [currentTraits, setCurrentTraits] = useState({
    noggles: '',
    hat: '',
    palette: '',
    pattern: '',
    eyes: '',
    mouth: '',
    size: '',
    nogglesColor: '#fff',
    eyeColors: Array(8).fill(null).map(() => Array(8).fill('#fff')),
    memberColor: '#fff',
    faceColors: {
      front: Array(8).fill(null).map(() => Array(8).fill('#fff')),
      left: Array(8).fill(null).map(() => Array(8).fill('#fff')),
      right: Array(8).fill(null).map(() => Array(8).fill('#fff')),
      top: Array(8).fill(null).map(() => Array(8).fill('#fff')),
      bottom: Array(8).fill(null).map(() => Array(8).fill('#fff')),
      back: Array(8).fill(null).map(() => Array(8).fill('#fff'))
    }
  });

  const [selectedTraits, setSelectedTraits] = useState({
    noggles: 'Random',
    hat: 'Random',
    palette: 'Random',
    pattern: 'Random',
    eyes: 'Random',
    mouth: 'Random',
    size: 'Random',
  });

  const masterNounsterRef = useRef(null);

  const updateTraits = (traits) => {
    setCurrentTraits(traits);
  };

  const updateSelectedTraits = (traits) => {
    setSelectedTraits(traits);
  };

  useEffect(() => {
    const regenerateShape = () => {
      console.log('Regenerating shape with selectedTraits:', selectedTraits);
      if (masterNounsterRef.current) {
        masterNounsterRef.current.regenerateShape(selectedTraits);
      }
    };
    regenerateShape();
  }, [selectedTraits]);

  const handleExportJSON = () => {
    if (masterNounsterRef.current) {
      masterNounsterRef.current.exportToJSON();
    }
  };

  const handleExportVOX = () => {
    if (masterNounsterRef.current) {
      masterNounsterRef.current.exportToVox();
    }
  };

  const handleExportUSDZ = () => {
    if (masterNounsterRef.current) {
      masterNounsterRef.current.exportToUSDZ();
    }
  };

  const handleExportTextureWithNogglesColor = () => {
    handleExportTexture(currentTraits.nogglesColor, currentTraits.eyeColors, currentTraits.memberColor, currentTraits.faceColors);
  };

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className="App-Hero">
      <div className="MasterNounster">
        <MasterNounster ref={masterNounsterRef} updateTraits={updateTraits} />
      </div>

      <div className={`panels-container ${isMobile ? 'panels-container-mobile' : ''}`}>
        <ControlPanel
          regenerateShape={(traits) => masterNounsterRef.current.regenerateShape(traits)}
          updateSelectedTraits={updateSelectedTraits}
          isMobile={isMobile}
        />
        <ExportPanel
          handleExportJSON={handleExportJSON}
          handleExportVOX={handleExportVOX}
          handleExportUSDZ={handleExportUSDZ}
          handleExportTexture={handleExportTextureWithNogglesColor}
        />
        {!isMobile && (
          <TraitDisplay currentTraits={currentTraits} paletteDetails={palettes} />
        )}
      </div>
    </div>
  );
};

export default AppHero;