// FullWidthSection.js

import React from 'react';
import '../css/App.css';
import yourImage from '../assets/nounster2Dgrid.png'; 

const FullWidthSection = () => {
  return (
    <div className="full-width-section max-w-7xl mx-auto p-4 flex "> 
      <div className="text-container">
        <h2 className='title-text'>Nounsters</h2>
        <p>
        Nounsters are an experimental attempt to improve the formation of onchain avatar communities. Nounsters attempt to bootstrap identity, community, governance, and a treasury that can be used to keep starknet strange.
        </p>

        <p>
          
            Read the twitter post <a className="text-violet-500 hover:text-violet-700 underline" href="https://x.com/briqNFT/status/1801622736093254078" target="_blank" rel="noopener noreferrer"> here </a>
        </p>
        <p>
        Read the project <a className="text-violet-500 hover:text-violet-700 underline" href="https://hackmd.io/@mbOX5LLRSB-yfQNwHDGWCg/rytb-VgU0" target="_blank" rel="noopener noreferrer">explainer</a>.
        </p>
      </div>
      <div className="image-container">
        <img src={yourImage} alt="Placeholder" />
      </div>
    </div>
  );
};

export default FullWidthSection;


