import * as THREE from "three";
import { createNounsterMaterial } from "../utilities/renderUtils";
import { hats } from './hats/hatIndex';

export const createNoggles = (groupRef, cubeGeometry, noggleColor, yOffset, randomNoggle) => {
  const noggleColorMaterial = createNounsterMaterial(noggleColor);
  const nogglesColors = Array.from({ length: 8 }, () => Array(8).fill(noggleColor));

  randomNoggle.cubes.forEach(({ x, y, type }) => {
    if (type === "noggles") {
      const cube = new THREE.Mesh(cubeGeometry, noggleColorMaterial);
      cube.position.set((x + (randomNoggle.id === 1 ? 1 : randomNoggle.id === 2 ? 0 : 3)) * 1, (y + yOffset) * 1, 8);
      groupRef.add(cube);

      // Ensure indices are within bounds
      if (y + yOffset >= 0 && y + yOffset < 8 && x >= 0 && x < 8) {
        nogglesColors[y + yOffset][x] = noggleColor;
      }
    }
  });

  return nogglesColors;
};

export const createEyes = (groupRef, cubeGeometry, majorColor, yOffset, randomNoggle, eyeConfig) => {
  let eyeOriginY;
  const eyeColors = Array.from({ length: 8 }, () => Array(8).fill(null));

  randomNoggle.cubes.forEach(({ x, y, type }) => {
    if (type === "origin") {
      eyeOriginY = y + yOffset;
      const xOffset = randomNoggle.id === 0 ? 3 : randomNoggle.id === 1 ? 1 : 0;

      // Ensure the 2x2 arrangement is always centered
      const centerX = 3;
      const centerY = 3;

      // Apply colors to the 2x2 area
      for (let i = centerX; i < centerX + 2; i++) {
        for (let j = centerY; j < centerY + 2; j++) {
          eyeColors[j][i] = majorColor;
        }
      }

      eyeConfig.briqs.forEach(({ pos: [dx, dy], data: { color } }) => {
        const eyeCube = new THREE.Mesh(cubeGeometry, new THREE.MeshStandardMaterial({ color: color === "none" ? majorColor : color }));
        eyeCube.position.set((x + dx + xOffset) * 1, (y + dy + yOffset) * 1, 8 * 1);
        groupRef.add(eyeCube);

        // Ensure indices are within bounds
        const gridX = dx + centerX;
        const gridY = dy + centerY;
        if (gridY >= 0 && gridY < 8 && gridX >= 0 && gridX < 8) {
          eyeColors[gridY][gridX] = color === "none" ? majorColor : color;
        }
      });
    }
  });
  return { eyeOriginY, eyeColors };
};

export const createMouth = (groupRef, cubeGeometry, majorColor, randomMouth) => {
  const xOffset = Math.floor(Math.random() * 3) + 1; // Random horizontal offset
  const yOffset = 1; // Fixed vertical position to be on rows 1 and 2

  randomMouth.briqs.forEach(({ pos: [x, y], data: { color } }) => {
    const cube = new THREE.Mesh(cubeGeometry, new THREE.MeshStandardMaterial({ color }));
    cube.position.set(
      (x + xOffset) * 1, 
      (y + yOffset) * 1, // Position on rows 1 and 2
      7 * 1 // Position on the front face (assuming z=7 is the front face)
    );
    groupRef.add(cube);
  });
};

export const createLimbs = (groupRef, cubeGeometry, majorColor, randomMemberConfig) => {
  randomMemberConfig.briqs.forEach(({ pos: [x, y, z] }) => {
    const memberCube = new THREE.Mesh(cubeGeometry, createNounsterMaterial(majorColor));
    memberCube.position.set(x * 1, y * 1, z * 1);
    groupRef.add(memberCube);
  });
  return majorColor;
};

export const createHat = (groupRef, cubeGeometry, accessoryColor, hatType) => {
  const hat = hats.find(h => h.name === hatType);

  if (hat && hat.data.name !== "None") {
    hat.data.briqs.forEach(({ pos: [x, y, z], data: { color } }) => {
      // Only change the color if it is originally red
      const newColor = color.toLowerCase() === "#ff0000" ? accessoryColor : color;
      const material = createNounsterMaterial(newColor);
      const hatCube = new THREE.Mesh(cubeGeometry, material);
      hatCube.position.set(x * 1, y * 1, z * 1);
      groupRef.add(hatCube);
    });
  }
};

