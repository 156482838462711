// eventUtils.js

let inactivityTimeout;

export const handleMouseDown = (rotateRef) => {
  clearTimeout(inactivityTimeout);
  inactivityTimeout = setTimeout(() => {
    rotateRef.current = true;
  }, 60000); // 60 seconds timeout
  rotateRef.current = false;
};

export const addEventListeners = (handleMouseDown, rotateRef, regenerateShape) => {
  const events = ["mousemove", "mousedown", "touchstart"];
  const handleMouseDownWithRotateRef = () => handleMouseDown(rotateRef);
  events.forEach((event) => window.addEventListener(event, handleMouseDownWithRotateRef));
  inactivityTimeout = setTimeout(() => {
    rotateRef.current = true;
  }, 60000); // 60 seconds timeout
  return inactivityTimeout;
};

export const removeEventListeners = (handleMouseDown, inactivityTimeout, rotateRef) => {
  const events = ["mousemove", "mousedown", "touchstart"];
  const handleMouseDownWithRotateRef = () => handleMouseDown(rotateRef);
  events.forEach((event) => window.removeEventListener(event, handleMouseDownWithRotateRef));
  clearTimeout(inactivityTimeout);
};