import Hat_Beanie from './Hat_Beanie.json';
import Hat_CapFront from './Hat_CapFront.json';
import Hat_Chapeau from './Hat_Chapeau.json';
import Hat_CapSide from './Hat_CapSide.json'
import Hat_Viking from './Hat_Viking.json'
import Hat_CapBack from './Hat_CapBack.json'
import Hat_SmallHat from './Hat_SmallHat.json'
import Hat_Joseon from './Hat_Joseon.json'
import Hat_Mohawk from './Hat_Mohawk.json'
import Hat_None from './Hat_None.json'
import Hat_Beret from './Hat_Beret.json'
import Hat_Ninja from './Hat_Ninja.json'
import Hat_Bonnet from './Hat_Bonnet.json'
import Hat_FlatHat from './Hat_FlatHat.json'
import Hat_NonLa from './Hat_NonLa.json'

export const hats = [
  {
    name: 'Beanie',
    data: Hat_Beanie
  },
  {
    name: 'CapFront',
    data: Hat_CapFront
  },
  {
    name: 'Chapeau',
    data: Hat_Chapeau
  },
  {
    name: 'CapSide',
    data: Hat_CapSide
  },
  {
    name: 'Viking',
    data: Hat_Viking
  },
  {
    name: 'CapBack',
    data: Hat_CapBack
  },
  {
    name: 'SmallHat',
    data: Hat_SmallHat
  },
  {
    name: 'Joseon',
    data: Hat_Joseon
  },
  {
    name: 'Mohawk',
    data: Hat_Mohawk
  },
  {
    name: 'None',
    data: Hat_None
  },
  {
    name: 'Beret',
    data: Hat_Beret
  },
  {
    name: 'Ninja',
    data: Hat_Ninja
  },
  {
    name: 'Bonnet',
    data: Hat_Bonnet
  },
  {
    name: 'Flat Hat',
    data: Hat_FlatHat
  },
  {
    name: 'NonLa',
    data: Hat_NonLa
  }
];

export const selectRandomHat = () => {
  return hats[Math.floor(Math.random() * hats.length)];
};