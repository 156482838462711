import * as THREE from "three";

export const setupScene = (scene, renderer) => {
  var fogColor = "rgb(212, 215, 225)"; // background color definition #D4D7E1
  scene.fog = new THREE.FogExp2(fogColor, 0.0025);
  renderer.setClearColor(fogColor);
};

export const setupLights = (scene) => {
  const topLight = new THREE.RectAreaLight(0xfefefe, 5.5, 30, 10);
  topLight.position.set(4, 12, 15);
  topLight.rotation.x = THREE.MathUtils.degToRad(-45);
  scene.add(topLight);

  const sideLight1 = new THREE.RectAreaLight(0xfefefe, 2, 10, 10);
  sideLight1.position.set(-12, 4, -5);
  sideLight1.rotation.y = THREE.MathUtils.degToRad(270);
  scene.add(sideLight1);

  const sideLight2 = new THREE.RectAreaLight(0xfefefe, 2, 10, 10);
  sideLight2.position.set(20, 4, -5);
  sideLight2.rotation.y = THREE.MathUtils.degToRad(90);
  scene.add(sideLight2);

  const sideLight3 = new THREE.RectAreaLight(0xfefefe, 2, 10, 10);
  sideLight3.position.set(10, -4, 15);
  sideLight3.rotation.y = THREE.MathUtils.degToRad(+30);
  scene.add(sideLight3);

  const ambientLight = new THREE.AmbientLight(0x404040); // Soft white light
  scene.add(ambientLight);
};

export const createOrthographicCamera = (aspect, frustumSize = 25) => {
  const camera = new THREE.OrthographicCamera(
    (frustumSize * aspect) / -2,
    (frustumSize * aspect) / 2,
    frustumSize / 2,
    frustumSize / -2,
    0.01,
    100
  );

  camera.position.set(
    -5.968496744769095,
    1.8771339470109105,
    12.00963470201468
  );
  camera.rotation.set(
    degreesToRadians(-8.88358624182255),
    degreesToRadians(-26.151687517936217),
    degreesToRadians(-3.9408864934747654)
  );

  return camera;
};

export const degreesToRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

export const createNounsterMaterial = (color) => {
  return new THREE.MeshStandardMaterial({
      color: color,
      roughness: 0.3,  // Adjust as needed for more/less glossiness
      metalness: 0.1,  // Small amount to simulate slight reflection
      envMapIntensity: 0.9, // Increase to enhance reflection effect
  });
};