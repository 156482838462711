import React, { useState, useEffect } from "react";
import "../css/App.css";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import FullWidthSection from './FullWidthSection';
import AppHero from "./AppHero";

//Testing 

const App = () => {
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHeader(true);
    }, 100000000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {showHeader && <AppHeader />}
      <AppHeader/>
      <AppHero />
      <FullWidthSection />
      <AppFooter />
    </div>
  );
};

export default App;


