
// Testing exporting UV texture

const handleExportTexture = (nogglesColor, eyeColors, memberColor, faceColors) => {
  const canvas = document.createElement('canvas');
  canvas.width = 320;
  canvas.height = 320;
  const ctx = canvas.getContext('2d');


  const panelOrder = [
    { name: 'back', colors: faceColors.back, x: 80, y: 0, scaleY: -1 },
    { name: 'bottom', colors: faceColors.bottom, x: 80, y: 80, rotate: 90, scaleX: -1 },
    { name: 'front', colors: faceColors.front, x: 80, y: 160 },
    { name: 'top', colors: faceColors.top, x: 80, y: 240, rotate: 90 },
    { name: 'right', colors: faceColors.right, x: 0, y: 160, scaleX: -1 },
    { name: 'left', colors: faceColors.left, x: 160, y: 160 },
    { name: 'noggles', colors: Array.from({ length: 8 }, () => Array(8).fill(nogglesColor)), x: 0, y: 0 },
    { name: 'eyes', colors: eyeColors, x: 0, y: 80 },
    { name: 'member', colors: Array.from({ length: 8 }, () => Array(8).fill(memberColor)), x: 240, y: 240 }
  ];

  panelOrder.forEach(({ name, colors, x, y, rotate = 0, scaleX = 1, scaleY = 1, width = 80, height = 80 }) => {
    ctx.save();
    ctx.translate(x + width / 2, y + height / 2);
    ctx.rotate((rotate * Math.PI) / 180);
    ctx.scale(scaleX, scaleY);
    ctx.translate(-(x + width / 2), -(y + height / 2));

    for (let rowIndex = 0; rowIndex < 8; rowIndex++) {
      for (let colIndex = 0; colIndex < 8; colIndex++) {
        let cellColor = colors[rowIndex][colIndex];
        if (name === 'eyes' && rowIndex >= 3 && rowIndex <= 4 && colIndex >= 3 && colIndex <= 4) {
          cellColor = eyeColors[rowIndex][colIndex] || nogglesColor;
        }
        if (!cellColor) {
          cellColor = nogglesColor;
        }
        ctx.fillStyle = cellColor;
        ctx.fillRect(x + colIndex * 10, y + rowIndex * 10, 10, 10);
      }
    }

    ctx.restore();
  });

  const link = document.createElement('a');
  link.href = canvas.toDataURL('image/png');
  link.download = 'nounster_UVtexture.png';
  link.click();
};

export { handleExportTexture };