import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import * as THREE from "three";
import "../css/App.css";
import { RectAreaLightUniformsLib } from "three/addons/lights/RectAreaLightUniformsLib.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { createOrthographicCamera, setupScene, setupLights } from "../utilities/renderUtils";
import { createShape } from "../Nounster/nounsterBuilder";
import { handleMouseDown, addEventListeners, removeEventListeners } from "../utilities/eventUtils";
import { exportToJSON } from "../utilities/ExportUtils/jsonExportUtils";
import { exportToVox } from "../utilities/ExportUtils/voxExportUtils";
import { exportToUSDZ } from "../utilities/ExportUtils/usdzExportUtils";

const MasterNounster = forwardRef(({ updateTraits }, ref) => {
  const mountRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);
  const groupRef = useRef(new THREE.Group());
  const parentGroupRef = useRef(new THREE.Group());
  const rotateRef = useRef(false);
  let inactivityTimeout;

  useImperativeHandle(ref, () => ({
    regenerateShape,
    exportToJSON: () => exportToJSON(groupRef.current),
    exportToVox: () => exportToVox(groupRef.current),
    exportToUSDZ: () => exportToUSDZ(groupRef.current),
  }));

  useEffect(() => {
    RectAreaLightUniformsLib.init();
    setupThreeJS();

    return () => {
      cleanupThreeJS();
    };
  }, []);

  const setupThreeJS = () => {
    const aspect = window.innerWidth / window.innerHeight;
    const camera = createOrthographicCamera(aspect);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    rendererRef.current = renderer;

    if (mountRef.current && !mountRef.current.contains(renderer.domElement)) {
      mountRef.current.appendChild(renderer.domElement);
    }

    setupScene(sceneRef.current, renderer);
    setupLights(sceneRef.current);
    regenerateShape();

    parentGroupRef.current.add(groupRef.current);
    sceneRef.current.add(parentGroupRef.current);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set(0, 0, 0);
    controls.update();
    controlsRef.current = controls;

    inactivityTimeout = addEventListeners(handleMouseDown, rotateRef, regenerateShape);

    const animate = () => {
      requestAnimationFrame(animate);
      if (rotateRef.current) {
        parentGroupRef.current.rotation.y += 0.008;
      }
      renderer.render(sceneRef.current, camera);
    };
    animate();
  };

  const cleanupThreeJS = () => {
    removeEventListeners(handleMouseDown, inactivityTimeout, rotateRef);
    controlsRef.current.dispose();
    disposeGroup(groupRef.current);
    disposeGroup(parentGroupRef.current);
    sceneRef.current.clear();
    rendererRef.current.dispose();
  };

  const disposeGroup = (group) => {
    group.children.forEach((child) => {
      if (child.geometry) child.geometry.dispose();
      if (child.material) {
        if (Array.isArray(child.material)) {
          child.material.forEach((material) => material.dispose());
        } else {
          child.material.dispose();
        }
      }
    });
    group.clear();
  };

  const regenerateShape = (traits) => {
    disposeGroup(groupRef.current);
    const generatedTraits = createShape(groupRef.current, traits);
    updateTraits(generatedTraits);
  };

  return <div className="fullscreen-container" ref={mountRef} />;
});

export default MasterNounster;