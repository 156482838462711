import React from 'react';

const ExportPanel = ({ handleExportJSON, handleExportVOX, handleExportUSDZ, handleExportTexture }) => {
  return (
    <div className="export-panel">
      <div className="export-section">
        <button className="export-button" onClick={handleExportUSDZ}>Export AR</button>
        <p className="export-note">Augmented Reality with your IOS device</p>
        </div>
      <div className="export-section">
        <button className="export-button" onClick={handleExportVOX}>Export .VOX</button>
        <p className="export-note">
          Start modeling with <a className="text-violet-900" href="https://ephtracy.github.io/" target="_blank" rel="noopener noreferrer">MagicaVoxel</a>!
        </p>
      </div>
      <div className="export-section">
        <button className="export-button" onClick={handleExportJSON}>Export .JSON</button>
        <p className="export-note">Load your Nounster to the <a className="text-violet-900" href="https://briq.construction/" target="_blank" rel="noopener noreferrer">Briq</a> website</p>
      </div>
      <div className="export-section">
        <button className="export-button" onClick={handleExportTexture}>Export Texture</button>
        <p className="export-note">Download a 320x320px UVmap image</p>
      </div>

    </div>
  );
};

export default ExportPanel;
