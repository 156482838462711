import React from 'react';

const TraitDisplay = ({ currentTraits, paletteDetails }) => {
  const palette = paletteDetails.find(p => {
    return p.id === currentTraits.palette || p.name === currentTraits.palette;
  });

  return (
    <div className="trait-display">
      <div className="trait-item">Noggles: {currentTraits.noggles}</div>
      <div className="trait-item">Hat: {currentTraits.hat}</div>
      <div className="trait-item">
        Palette: {palette ? palette.name : `Undefined (${currentTraits.palette})`}
        {palette && (
          <div className="palette-info">
            <div className="palette-colors">
              {palette.colors.slice(0, 4).map((color, index) => (
                <div
                  key={index}
                  className="color-square"
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="trait-item">Pattern: {currentTraits.pattern}</div>
      <div className="trait-item">Eyes: {currentTraits.eyes}</div>
      <div className="trait-item">Mouth: {currentTraits.mouth}</div>
      <div className="trait-item">Size: {currentTraits.size}</div>
    </div>
  );
};

export default TraitDisplay;