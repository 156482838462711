import * as THREE from 'three';
import { createNounsterMaterial } from '../../utilities/renderUtils';

import PatternEdges from './Pattern_Edges.json';
import PatternX from './Pattern_X.json';
import PatternBranch from './Pattern_Branch.json';
import PatternChecker from './Pattern_Checker.json';
import PatternHorizontalStripes from './Pattern_HorizontalStripes.json';
import PatternLargeHorizontalStripes from './Pattern_LargeHorizontalStripes.json';
import PatternPlain from './Pattern_Plain.json';
import PatternSpiral from './Pattern_Spiral.json';
import PatternVerticalStripes from './Pattern_VerticalStripes.json';
import PatternLargeVerticalStripes from './Pattern_LargeVerticalStripes.json';
import PatternGlitch from './Pattern_Glitch.json';
import PatternPyramid from './Pattern_Pyramid.json'
import PatternDrip from './Pattern_Drip.json'
import PatternDiagonal from './Pattern_Diagonal.json'
import PatternWall from './Pattern_Wall.json'
import PatternCheese from './Pattern_Cheese.json'
import PatternMess from './Pattern_Mess.json'
import PatternUroko from './Pattern_Uroko.json'


export const patterns = [
  {
    name: 'Edges',
    data: PatternEdges
  },
  {
    name: 'X',
    data: PatternX
  },
  {
    name: 'Branch',
    data: PatternBranch
  },
  {
    name: 'Checker',
    data: PatternChecker
  },
  {
    name: 'Horizontal Stripes',
    data: PatternHorizontalStripes
  },
  {
    name: 'Large Horizontal Stripes',
    data: PatternLargeHorizontalStripes
  },
  {
    name: 'Plain',
    data: PatternPlain
  },
  {
    name: 'Spiral',
    data: PatternSpiral
  },
  {
    name: 'Vertical Stripes',
    data: PatternVerticalStripes
  },
  {
     name: 'Large Vertical Stripes',
    data: PatternLargeVerticalStripes
  },
  {
    name: 'Glitch',
    data: PatternGlitch
  },
  {
    name: 'Pyramid',
    data: PatternPyramid
  },
  {
    name: 'Drip',
    data: PatternDrip
  },
  {
    name: 'Diagonal',
    data: PatternDiagonal
  },
  {
    name: 'Wall',
    data: PatternWall
  },
  {
    name: 'Cheese',
    data: PatternCheese
  },
  {
    name: 'Mess',
    data: PatternMess
  },
  {
    name: 'Uroko',
    data: PatternUroko
  }
  // Add more patterns here as needed
];

export const applyPatterns = (selectedPattern, groupRef, paletteColors) => {
  const { data: patternData } = selectedPattern;
  const colorMapping = {}; // To map original colors to new palette colors

  // Identify unique colors in the pattern
  const uniqueColors = [...new Set(patternData.briqs.map(b => b.data.color))];

  // Map unique colors to palette colors
  uniqueColors.forEach((color, index) => {
    colorMapping[color] = paletteColors[index % paletteColors.length];
  });

  // Define translation offsets
  const xOffset = 3;
  const yOffset = -4;

  // Apply pattern
  patternData.briqs.forEach(briq => {
    const { pos, data } = briq;
    const [x, y, z] = pos;
    const color = colorMapping[data.color];

    const material = createNounsterMaterial(color);
    const cube = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), material);
    cube.position.set(x + xOffset, y + yOffset, z); // Apply translation offsets
    groupRef.add(cube);
  });

  // Return used colors for further use
  return {
    patternColors: Object.values(colorMapping),
    majorColor: colorMapping[uniqueColors[0]] // Assuming the first color is the major color
  };
};