// usdzExportUtils.js
import * as THREE from 'three';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter';

export const exportToUSDZ = async (groupRef) => {
    // Ensure the groupRef is valid
    if (!groupRef) {
        console.error("Invalid group reference");
        return;
    }

    // Scale factor for USDZ export
    const scaleFactor = 0.08; // Change this from 0.001 to 0.01

    // Create a temporary group to hold the scaled objects
    const tempGroup = new THREE.Group();
    tempGroup.copy(groupRef, true);

    // Apply the scale to the temporary group
    tempGroup.scale.set(scaleFactor, scaleFactor, scaleFactor);

    // Calculate the bounding box of the temporary group
    const box = new THREE.Box3().setFromObject(tempGroup);
    const center = box.getCenter(new THREE.Vector3());

    // Move the temporary group to the origin
    tempGroup.position.sub(center);

    // Add the temporary group to a new scene to update world matrices
    const scene = new THREE.Scene();
    scene.add(tempGroup);

    // Update the world matrices of all descendants
    scene.updateWorldMatrix(false, true);

    // Create a new USDZExporter instance
    const exporter = new USDZExporter();

    try {
        // Parse the temporary group to generate the USDZ file
        const arraybuffer = await exporter.parse(tempGroup);

        // Create a Blob from the array buffer
        const blob = new Blob([arraybuffer], { type: 'application/octet-stream' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'AR_nounster.usdz';
        link.click();
    } catch (error) {
        console.error("Error exporting to USDZ:", error);
    }
};