import * as THREE from 'three';  // Necessary for creating 3D objects and scenes
import { getPalette, selectRandomItem, getDistinctRandomColors, assignColors } from "../utilities/utilities";  // Utility functions for palette and random selection
import { createNoggles, createEyes, createMouth, createLimbs, createHat } from "./createUtils";  // Functions to create various parts of the Nounster
import { patterns, applyPatterns } from "./patterns/patternIndex";  // Patterns for the Nounster
import nogglesConfig from "./noggles//noggles.json";
import eyesConfig from '../configurations/Json/eyesConfig.json';  // Configuration for eyes
import mouthConfig from "../configurations/Json/mouthConfig.json";  // Configuration for mouths
import limbsConfig from "./limbsConfig.json";
import { selectRandomHat } from "./hats/hatIndex";  // Function to select a random hat


export const createShape = (groupRef, selectedTraits = {}) => {
  const cubeSize = 1;
  const selectedPalette = getPalette(selectedTraits.palette);
  const paletteColors = selectedPalette.colors.slice(0, 4);

  // Step 1: Track which colors are used
  const usedColors = new Set();
  const availableColors = [...paletteColors];

  // Step 2: Apply pattern and get pattern colors
  const selectedPattern = selectedTraits.pattern === 'Random' ? selectRandomItem(patterns) : patterns.find(p => p.name === selectedTraits.pattern) || selectRandomItem(patterns);
  const { patternColors, majorColor } = applyPatterns(selectedPattern, groupRef, getDistinctRandomColors(paletteColors, 4, selectedPalette.name, selectedPattern.name));

  // Add pattern colors to used colors
  patternColors.forEach(color => usedColors.add(color));
  usedColors.add(majorColor);

  // Step 3: Assign colors to noggles and hat based on remaining colors
  const { nogglesColor, hatColor } = assignColors(paletteColors, patternColors, usedColors);
  usedColors.add(nogglesColor);
  usedColors.add(hatColor);

  const cubeGeometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);

  const yOffset = Math.floor(Math.random() * 2) + 3; // defines the vertical offset of the noggles

  const randomNoggle = selectedTraits.noggles === 'Random' ? selectRandomItem(nogglesConfig) : nogglesConfig.find(n => n.name === selectedTraits.noggles) || selectRandomItem(nogglesConfig);
  const nogglesColors = createNoggles(groupRef, cubeGeometry, nogglesColor, yOffset, randomNoggle);

  const eyeConfig = selectedTraits.eyes === 'Random' ? selectRandomItem(eyesConfig) : eyesConfig.find(e => e.name === selectedTraits.eyes) || selectRandomItem(eyesConfig);
  const { eyeOriginY, eyeColors } = createEyes(groupRef, cubeGeometry, majorColor, yOffset, randomNoggle, eyeConfig);

  const randomMouth = selectedTraits.mouth === 'Random' ? selectRandomItem(mouthConfig) : mouthConfig.find(m => m.name === selectedTraits.mouth) || selectRandomItem(mouthConfig);
  createMouth(groupRef, cubeGeometry, majorColor, randomMouth);

  const randomMemberConfig = selectedTraits.size === 'Random' ? selectRandomItem(limbsConfig) : limbsConfig.find(m => m.name === selectedTraits.size) || selectRandomItem(limbsConfig);
  const memberColor = createLimbs(groupRef, cubeGeometry, majorColor, randomMemberConfig);

  const randomHat = selectedTraits.hat === 'Random' ? selectRandomHat().name : selectedTraits.hat || selectRandomHat().name;
  createHat(groupRef, cubeGeometry, hatColor, randomHat);

  if (randomMemberConfig.name === 'S') {
    groupRef.position.set(-3, -5, -3);
  } else if (randomMemberConfig.name === 'L') {
    groupRef.position.set(-3.5, -1, -3);
  } else {
    groupRef.position.set(-3.5, -3, -3);
  }

  const faceColors = {
    front: Array.from({ length: 8 }, () => Array(8).fill('#fff')),
    left: Array.from({ length: 8 }, () => Array(8).fill('#fff')),
    right: Array.from({ length: 8 }, () => Array(8).fill('#fff')),
    top: Array.from({ length: 8 }, () => Array(8).fill('#fff')),
    bottom: Array.from({ length: 8 }, () => Array(8).fill('#fff')),
    back: Array.from({ length: 8 }, () => Array(8).fill('#fff'))
  };

  groupRef.children.forEach(cube => {
    const { x, y, z } = cube.position;
    const color = cube.material.color.getStyle();
    if (z === 7) { // Front face
      const flippedX = 7 - x; // Flip horizontally
      if (faceColors.front[y] && faceColors.front[y][flippedX] !== undefined) {
        faceColors.front[y][flippedX] = color;
      }
    }
    if (x === 0) { // Left face
      const flippedZ = 7 - z; // Flip horizontally
      if (faceColors.left[y] && faceColors.left[y][flippedZ] !== undefined) {
        faceColors.left[y][flippedZ] = color;
      }
    }
    if (x === 7) { // Right face
      const flippedZ = 7 - z; // Flip horizontally
      if (faceColors.right[y] && faceColors.right[y][flippedZ] !== undefined) {
        faceColors.right[y][flippedZ] = color;
      }
    }
    if (y === 7) { // Top face
      const flippedZ = 7 - z; // Flip horizontally
      if (faceColors.top[x] && faceColors.top[x][flippedZ] !== undefined) {
        faceColors.top[x][flippedZ] = color;
      }
    }
    if (y === 0) { // Bottom face
      const flippedZ = 7 - z; // Flip horizontally
      if (faceColors.bottom[x] && faceColors.bottom[x][flippedZ] !== undefined) {
        faceColors.bottom[x][flippedZ] = color;
      }
    }
    if (z === 0) { // Back face
      const flippedX = 7 - x; // Flip horizontally
      if (faceColors.back[y] && faceColors.back[y][flippedX] !== undefined) {
        faceColors.back[y][flippedX] = color;
      }
    }
  });

  const paletteIdOrName = selectedPalette.name;

  return {
    noggles: randomNoggle.name,
    hat: randomHat,
    palette: paletteIdOrName,
    pattern: selectedPattern.name,
    eyes: eyeConfig.name,
    mouth: randomMouth.name,
    size: randomMemberConfig.name,
    nogglesColor,
    eyeColors,
    memberColor,
    faceColors
  };
};