//utilities.js
import * as THREE from "three";
import palettes from '../Nounster/palettesList.json';



// Function to draw XYZ axes
export function drawAxes(scene) {
  const axesLength = 20; // Length of the axes

  const xAxisMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 }); // Red for X axis
  const yAxisMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 }); // Green for Y axis
  const zAxisMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff }); // Blue for Z axis

  const xAxisGeometry = new THREE.BufferGeometry().setFromPoints([
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(axesLength, 0, 0),
  ]);
  const yAxisGeometry = new THREE.BufferGeometry().setFromPoints([
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(0, axesLength, 0),
  ]);
  const zAxisGeometry = new THREE.BufferGeometry().setFromPoints([
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(0, 0, axesLength),
  ]);

  const xAxis = new THREE.Line(xAxisGeometry, xAxisMaterial);
  const yAxis = new THREE.Line(yAxisGeometry, yAxisMaterial);
  const zAxis = new THREE.Line(zAxisGeometry, zAxisMaterial);

  scene.add(xAxis);
  scene.add(yAxis);
  scene.add(zAxis);
}

export const getDistinctRandomColors = (
  palette,
  n,
  paletteName,
  patternName
) => {
  if (n > palette.length) {
    throw new Error(
      `The number of colors requested (${n}) exceeds the palette size (${palette.length}) for palette ${paletteName} in pattern ${patternName}.`
    );
  }

  const colors = [];
  const usedIndices = new Set();

  while (colors.length < n) {
    const index = Math.floor(Math.random() * palette.length);
    if (!usedIndices.has(index)) {
      colors.push(palette[index]);
      usedIndices.add(index);
    }
  }

  return colors;
};

export const getPalette = (selectedPalette) => {
  if (selectedPalette === 'Random') {
    return selectRandomItem(palettes);
  }

  const palette = palettes.find(p => p.name === selectedPalette);
  return palette ? palette : selectRandomItem(palettes);
};

export const selectRandomItem = (array) => array[Math.floor(Math.random() * array.length)];

export const selectNoggleColor = (availableColors, usedColors) => {
  const unusedColors = availableColors.filter(color => !usedColors.has(color));
  const colorsToPickFrom = unusedColors.length > 0 ? unusedColors : availableColors;
  let noggleColor;
  [noggleColor] = getDistinctRandomColors(colorsToPickFrom, 1);
  return noggleColor;
};

export const assignColors = (palette, patternColors, usedColors) => {
  const remainingColors = palette.filter(color => !usedColors.has(color));

  let nogglesColor;
  let hatColor;

  if (remainingColors.length === 2) {
    // Direct assignment when two colors are left
    [nogglesColor, hatColor] = remainingColors;
  } else if (remainingColors.length === 1) {
    // Assign the one remaining color to either noggles or hat
    nogglesColor = remainingColors[0];
    hatColor = palette[Math.floor(Math.random() * palette.length)];
  } else if (remainingColors.length === 3) {
    // Randomly assign two out of three remaining colors
    const [first, second] = getDistinctRandomColors(remainingColors, 2);
    nogglesColor = first;
    hatColor = second;
  }

  return { nogglesColor, hatColor };
};

