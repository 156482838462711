

export const exportToJSON = (groupRef) => {
    const briqs = groupRef.children.map(cube => ({
      pos: [cube.position.x, cube.position.y, cube.position.z],
      data: {
        material: "0x1",
        color: `#${cube.material.color.getHexString()}`,
      }
    }));
  
    const nounsterData = {
      id: "xxx",
      name: "Nounsters",
      description: "A Nounster character",
      regionSize: 100000,
      version: 1,
      briqs
    };
  
    const jsonString = JSON.stringify(nounsterData, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "nounster.json";
    link.click();
  };