// voxExportUtils.js

const VOX_VERSION = 150;

const createVoxHeader = () => {
    const buffer = new ArrayBuffer(8);
    const view = new DataView(buffer);
    view.setUint32(0, 0x20584f56, true); // 'VOX ' in ASCII
    view.setUint32(4, VOX_VERSION, true);
    return buffer;
};

const createMainChunk = (sizeChunks, xyziChunks, paletteChunk) => {
    const mainChunkID = new Uint8Array([0x4d, 0x41, 0x49, 0x4e]); // 'MAIN' in ASCII
    const contentSize = new Uint32Array([0]); // No content
    const childrenSize = new Uint32Array([sizeChunks.byteLength + xyziChunks.byteLength + paletteChunk.byteLength]);

    return concatBuffers(mainChunkID.buffer, contentSize.buffer, childrenSize.buffer, sizeChunks, xyziChunks, paletteChunk);
};

const createSizeChunk = (x, y, z) => {
    const buffer = new ArrayBuffer(24); // Corrected to allocate enough bytes
    const view = new DataView(buffer);
    view.setUint32(0, 0x455a4953, true); // 'SIZE' in ASCII
    view.setUint32(4, 12, true); // Content size
    view.setUint32(8, 0, true); // No children
    view.setUint32(12, x, true);
    view.setUint32(16, y, true);
    view.setUint32(20, z, true);
    console.log("Size chunk created:", new Uint8Array(buffer));
    return buffer;
};

const createXYZIChunk = (voxels) => {
    const buffer = new ArrayBuffer(16 + voxels.length * 4); // Corrected to allocate enough bytes
    const view = new DataView(buffer);
    view.setUint32(0, 0x495a5958, true); // 'XYZI' in ASCII
    view.setUint32(4, 4 + voxels.length * 4, true); // Content size
    view.setUint32(8, 0, true); // No children
    view.setUint32(12, voxels.length, true);
    voxels.forEach((voxel, index) => {
        view.setUint8(16 + index * 4, voxel.x);
        view.setUint8(16 + index * 4 + 1, voxel.y);
        view.setUint8(16 + index * 4 + 2, voxel.z);
        view.setUint8(16 + index * 4 + 3, voxel.colorIndex);
    });
    console.log("XYZI chunk created:", new Uint8Array(buffer));
    return buffer;
};

const createPaletteChunk = (palette) => {
    const buffer = new ArrayBuffer(1036); // Corrected to allocate enough bytes
    const view = new DataView(buffer);
    view.setUint32(0, 0x41424752, true); // 'RGBA' in ASCII
    view.setUint32(4, 256 * 4, true); // Content size
    view.setUint32(8, 0, true); // No children
    palette.forEach((color, index) => {
        const offset = 12 + index * 4;
        view.setUint8(offset, color.r);
        view.setUint8(offset + 1, color.g);
        view.setUint8(offset + 2, color.b);
        view.setUint8(offset + 3, color.a);
    });
    console.log("Palette chunk created:", new Uint8Array(buffer));
    return buffer;
};

const concatBuffers = (...buffers) => {
    const totalLength = buffers.reduce((acc, buffer) => acc + buffer.byteLength, 0);
    const tempBuffer = new Uint8Array(totalLength);
    let offset = 0;
    buffers.forEach(buffer => {
        tempBuffer.set(new Uint8Array(buffer), offset);
        offset += buffer.byteLength;
    });
    console.log("Buffers concatenated:", tempBuffer);
    return tempBuffer.buffer;
};

export const exportToVox = (groupRef) => {
    const voxels = [];
    const palette = new Map();
    let colorIndex = 1;

    const size = 24; // Updated size to 24x24x24

    groupRef.children.forEach(cube => {
        const position = cube.position;
        const color = cube.material.color;
        const hexColor = color.getHexString();
        if (!palette.has(hexColor)) {
            palette.set(hexColor, colorIndex++);
        }
        voxels.push({
            x: Math.round(position.x + size / 2),
            y: Math.round(position.y + size / 2),
            z: Math.round(position.z + size / 2),
            colorIndex: palette.get(hexColor)
        });
    });

    const paletteArray = Array.from(palette.keys()).map(hex => {
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);
        return { r, g, b, a: 255 };
    });

    while (paletteArray.length < 256) {
        paletteArray.push({ r: 0, g: 0, b: 0, a: 0 });
    }

    const header = createVoxHeader();
    const sizeChunk = createSizeChunk(size, size, size);
    const xyziChunk = createXYZIChunk(voxels);
    const paletteChunk = createPaletteChunk(paletteArray);
    const mainChunk = createMainChunk(sizeChunk, xyziChunk, paletteChunk);

    const finalBuffer = concatBuffers(header, mainChunk);

    const blob = new Blob([finalBuffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "nounster.vox";
    link.click();
};