import React, { useState } from 'react';
import nogglesConfig from '../Nounster/noggles/noggles.json';
import palettes from '../Nounster/palettesList.json';
import { patterns } from '../Nounster/patterns/patternIndex';
import eyesConfig from '../configurations/Json/eyesConfig.json';
import mouthConfig from '../configurations/Json/mouthConfig.json';
import limbsConfig from '../Nounster/limbsConfig.json';
import { hats, selectRandomHat } from '../Nounster/hats/hatIndex';

const ControlPanel = ({ regenerateShape, updateSelectedTraits, handleExportJSON, handleExportVOX, handleExportUSDZ, isMobile }) => {
  const [traits, setTraits] = useState({
    noggles: 'Random',
    hat: 'Random',
    palette: 'Random',
    pattern: 'Random',
    eyes: 'Random',
    mouth: 'Random',
    size: 'Random',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTraits((prevTraits) => ({ ...prevTraits, [name]: value }));
  };

  const handleGenerate = () => {
    const selectedTraits = { ...traits };

    if (selectedTraits.hat === 'Random') {
      selectedTraits.hat = selectRandomHat().name;
    }

    updateSelectedTraits(selectedTraits);
    regenerateShape(selectedTraits);
  };

  const traitOptions = {
    noggles: nogglesConfig.map(item => item.name),
    hat: hats.map(hat => hat.name),
    palette: palettes.map(item => item.name),
    pattern: patterns.map(item => item.name),
    eyes: eyesConfig.map(item => item.name),
    mouth: mouthConfig.map(item => item.name),
    size: limbsConfig.map(item => item.name),
  };

  return (
    <div className={`control-panel ${isMobile ? 'control-panel-mobile' : ''}`}>
      <button className="generate-button" onClick={handleGenerate}>
        Generate
      </button>
      {Object.keys(traits).map((trait, index) => (
        <div key={index} className="dropdown">
          <label>{trait.charAt(0).toUpperCase() + trait.slice(1)}</label>
          <select
            name={trait}
            value={traits[trait]}
            onChange={handleChange}
          >
            <option value="Random">Random</option>
            {traitOptions[trait].map((option, idx) => (
              <option key={idx} value={option}>{option}</option>
            ))}
          </select>
        </div>
      ))}
    </div>
  );
};

export default ControlPanel;