import React from "react";
import "../css/AppHeader.css";
import logo from "../assets/nounster.png";

const AppHeader = () => {
  return (
    <header className="App-header header-slide-in">
      <section className="max-w-7xl mx-auto p-4 flex justify-between items-center">
        <div className="flex items-center">
          <a href="#hero">
            <img src={logo} alt="Logo" className="header-logo" />
          </a>
        </div>

      </section>
    </header>
  );
};

export default AppHeader;
